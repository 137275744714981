/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
export { wrapPageElement } from './src/wrap-page-element'

// The following import prevents a Font Awesome icon server-side rendering bug,
// where the icons flash from a very large icon down to a properly sized one:
import '@fortawesome/fontawesome-svg-core/styles.css'
// Prevent fontawesome from adding its CSS since we did it manually above:
const { config } = require('@fortawesome/fontawesome-svg-core')
config.autoAddCss = false /* eslint-disable import/first */

export const onClientEntry = () => {
  window.addEventListener('load', () => {
    document.body.className = document.body.className.replace(/\bno-js\b/, 'js')
    initializeToggleMenu('__nm-script', '#toggle', '#menu')
  })
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  initializeToggleMenu('__nm-script', '#toggle', '#menu')
}
