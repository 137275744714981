/**
 * Configuration settings for react-i18next
 */
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n.use(initReactI18next).init({
  resources: {
    // import translations
    en: {
      translation: require('./locales/en/translation.json')
    },
    es: {
      translation: require('./locales/es/translation.json')
    },
    fr: {
      translation: require('./locales/fr/translation.json')
    }
  },
  lng: 'en',
  supportedLngs: ['en', 'es', 'fr'],
  fallbackLng: 'en', // default language
  debug: process.env.NODE_ENV === 'development',
  interpolation: {
    escapeValue: false
  }
})

export default i18n
