// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-canada-tsx": () => import("./../../../src/pages/canada.tsx" /* webpackChunkName: "component---src-pages-canada-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-blog-list-tsx": () => import("./../../../src/templates/blog-list.tsx" /* webpackChunkName: "component---src-templates-blog-list-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-canada-covid-19-tsx": () => import("./../../../src/templates/canada-covid19.tsx" /* webpackChunkName: "component---src-templates-canada-covid-19-tsx" */),
  "component---src-templates-canada-health-tsx": () => import("./../../../src/templates/canada-health.tsx" /* webpackChunkName: "component---src-templates-canada-health-tsx" */),
  "component---src-templates-canada-toronto-tsx": () => import("./../../../src/templates/canada-toronto.tsx" /* webpackChunkName: "component---src-templates-canada-toronto-tsx" */),
  "component---src-templates-chart-list-tsx": () => import("./../../../src/templates/chart-list.tsx" /* webpackChunkName: "component---src-templates-chart-list-tsx" */),
  "component---src-templates-data-viz-tsx": () => import("./../../../src/templates/data-viz.tsx" /* webpackChunkName: "component---src-templates-data-viz-tsx" */),
  "component---src-templates-doc-list-tsx": () => import("./../../../src/templates/doc-list.tsx" /* webpackChunkName: "component---src-templates-doc-list-tsx" */),
  "component---src-templates-doc-tsx": () => import("./../../../src/templates/doc.tsx" /* webpackChunkName: "component---src-templates-doc-tsx" */)
}

